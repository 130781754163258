































// Components
import { Component, Vue } from 'vue-property-decorator';

// GraphQL
import dashboardAppsyncClient from '@/lib/appsync/dashboard';
import getReliabilityMetric from '@/graphql/getReliabilityMetric.graphql';

// Interfaces
import MainTimeOption from '@/interfaces/mainTimeOption'; // eslint-disable-line
import ReliabilityMetric from '@/interfaces/reliabilityMetric'; // eslint-disable-line

// Dates
import { formatDate } from '@/utils/dateHelpers';

import { namespace } from 'vuex-class';
import { addMinutes, startOfDay } from 'date-fns';

const appModule = namespace('appModule');

@Component
export default class ReliabilityByDate extends Vue {
  @appModule.Getter
  public currentTimeOption!: MainTimeOption

  public reliabilityMetrics: ReliabilityMetric[] = [];

  public loading: boolean = false;

  public async fetchData(): Promise<void> {
    this.loading = true;
    /*
     Fetch reliability metrics
    */
    const {
      data:
        {
          get_reliability_metric: {
            reliability_dict: relDict,
          },
        },
    } = await dashboardAppsyncClient.query({
      query: getReliabilityMetric,
      variables: {
        env: 'prod',
        start_date:
          formatDate(startOfDay(addMinutes(
            new Date(),
            new Date().getTimezoneOffset() - this.currentTimeOption.normalizedValue * 1440,
          )), "yyyy-MM-dd'T'HH:mm:ss"),
        end_date: formatDate(startOfDay(addMinutes(new Date(), new Date().getTimezoneOffset())),
          "yyyy-MM-dd'T'HH:mm:ss"),
      },
    });
    this.reliabilityMetrics = await Object.entries(JSON.parse(relDict)).map((i, idx) => ({
      id: idx,
      time: formatDate(new Date(i[0]), 'MMMM do'),
      value: (Number(i[1]) * 100).toFixed(2),
    }));

    this.loading = false;
  }

  async created() {
    await this.fetchData();
    this.$eventBus.$on('timeAppChanged', () => {
      this.fetchData();
    });
  }
}
